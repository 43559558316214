.btn {
  border: none;
  
  font-family: $font1;
  height: 40px;
  font-size: 16px;
  line-height: 1;
  border-radius: 20px;
  padding: 0 20px;
  color: white;
  &:hover{
    color: darken(white,5%);
  }

  svg{
    margin: 0 10px 0 0;
    color: inherit;
  }

  &-list{
    .btn,button{
      margin: 0 5px;
    }
  }

  &.btn-primary,&.btn-secondary{
    box-shadow: 0 5px 10px rgba(black, .3);
  }

  &.btn-primary {
    color: $accent;
    border: 2px solid $accent;
    background-color: transparent;
    transition: all 0.3s ease;
    transform: translateY(0px);
    &:not(:disabled):not(.disabled):active {
      background: $accent;
      color: $btn_dark;
      box-shadow: 0 5px 10px rgba(black, 0);
      transform: translateY(2px);
      border-color: transparent;
    }
    &:hover {
      background: $accent;
      color: $btn_dark;

      &.icon:before {
        color: $btn_dark;
      }

    }

    &.icon:before {
      color: $accent;
      transition: all 0.3s ease;
    }

  }

  &.btn-success {
      color: $green;
      border: 2px solid $green;
      background-color: transparent;
      transition: all 0.3s ease;
      transform: translateY(0px);

      &:not(:disabled):not(.disabled):active {
          background: $green;
          color: $btn_dark;
          box-shadow: 0 5px 10px rgba(black, 0);
          transform: translateY(2px);
          border-color: transparent;
      }

      &:hover {
          background: $green;
          color: $btn_dark;

          &.icon:before {
              color: $btn_dark;
          }

      }

      &.icon:before {
          color: $accent;
          transition: all 0.3s ease;
      }

  }

  &.btn-secondary {
    color: white;
    border: 2px solid;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover {
      color: white;
      background: $btn_dark;
      border-color: $btn_dark;

      &.icon:before {
        color: white;
      }

    }

    &.icon:before {
      color: $btn_dark;
      transition: all 0.3s ease;
    }

    
  }

  &.btn-danger {
    color: #c82333;
    border: 2px solid #c82333;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover {
      color: white;
      background: #c82333;

      &.icon:before {
        color: white;
      }
  
      svg{
        color: white;
      }
    }

    &.icon:before {
      color: #c82333;
      transition: all 0.3s ease;
    }

    svg{
      color: #c82333;
    }
  }

  &.btn-back {
    z-index: 9999;
  }

  &.icon {
    &:before {
      display: inline-block;
      width: 30px;
      text-align: center;
    }

  }

  .fab {
    margin: 0 10px;
  }
}

.allowed-successfully {
  color: #fff;
  background-color: #F79A00;
  border-radius: 10px;
  text-align: center;
}

.form-group{
  .form-control {
    border-radius: 3px;
    border-width: 2px;
  }
}

.admin-accordions {
  .admin-dash-table {
    margin-bottom: 5px;

    .user-table-content {
      margin-bottom: 0;
      width: 95%;
      margin: auto;
      font-size: 13px;
      border-spacing: 0 1px;
      border-collapse: inherit;
      color: white;
      tr {
        margin-bottom: 1px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        background-color: $theme-bg-2;

        &:hover {
          cursor: pointer;
        }
        
        td {
          border: none;
          flex: 3;

          &.compo-name {
            flex: 6;
          }
          &.compo-price-icon {
            flex: 1;
          }
          &.compo-edit-icon {
            flex: 1;
            text-align: right;
            .btn {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

.list-user {
  th {
    &:last-child {
      width: 10px;
    }
  }
}

.coder-info {
  .accordion-toggle {
    cursor: pointer;
  }

  .accordion-toggle.active {
    transform: rotate(180deg);
    transition: all .2s;
  }

  .edit-btn {
    cursor: pointer;
  }

  h3 {
    margin-bottom: 0;
    font-size: 1.3rem;
    font-weight: 300;
  }
  .w-100 {
    position: relative;
    .badge-rounded {
      width: 30px;
      height: 30px;
      padding: 9px 0;
      position: absolute;
      left: -100px;
      top: -12px;
      background-color: $col4;
    }
  }

  .coder-location {
    font-size: 11px;
  }
 
  .edit-profile-btn {
    cursor: pointer;
    width: 15px;
  }

  .coder-info-toggle {
    .accordion-toggle {
      transition: all .2s ease-in-out;
      color: white;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}

.coders-accordion-content {
	overflow: hidden;
	max-height: 0;
  transition: all .2s ease-in-out;
  
  &.active {
    max-height: fit-content;
    max-height:-moz-max-content;
  }
}

.coder-search, .amount-search, .id-search, .project-search, .city-search {
  .form-group {
    flex: 1;
    margin-bottom: 0;

    .form-control {
      margin-left: 3px;
      border:none;
      color: white;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .search-icon {
    width: 18px;
    color: rgba(255,255,255,0.7);
  }

  .filter-icon {
    width: 18px;
    margin-right: 10px;
    margin-left: 4px;
  }

  .sort-wrapper {
    cursor: pointer;
  }
}

.dash-table {
  tbody {
    tr {
      transition: all .3s;
      cursor: pointer;
      
      &:hover {
        background-color: rgba(255,255,255,.1);
      }
    }
  }
  .alert-icon {
    color: red;
    width: 12px;
  }
  .view-icon {
    width: 20px;
  }
  .view-btn {
    margin-left: 5px;
    text-decoration: none;
    cursor: pointer;
    color: white;

    &:hover {
      text-decoration: none;
    }
  }
}

.dash-header {
  padding: 0;
  margin-bottom: 50px !important;

  .coder-logo {
    max-width: 200px;
  }

  .power-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
      color: rgba(255,255,255,.8);
    }
  }
}
.dash-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  background-color: $col5;
  opacity: .7;
}
.footer-inside{
  padding: 8px !important;
}
.copyright-text{
  float: left;
}
.social-icons{
  float: right;
}
.social-icons li{
  float: left;
  margin-right: 5px;
  list-style-type: none;
}
.coder-info {
  .accordion-toggle.active {
    transform: rotate(180deg);
    transition: all .2s;
  }
  .edit-btn {
    cursor: pointer;
  }
}

.terms-area {
  border-radius: 3px;
  background-color: $dark;
}


.payer-component-checkmark{
  input{
    @include hide_input();
    &+label{
      margin-bottom: 0;
      span{
        color: white;
        $rad:15px;
        content:'';
        width: $rad;
        height: $rad;
        display: block;
        border-radius: 3px;
        border: 2px solid;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          display: block;
          width: 6px;
          height: 12px;
          border-color: inherit;
          border: 2px solid white;
          border-left: none;
          border-top: none;
          top: 50%;
          left: 50%;
          color: $theme-bg-2;
          transform: translateX(8%) translateY(-83%) rotate(45deg);
          background: currentColor;
          box-shadow: 2px 0 1px currentColor;

          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &:checked{
      &+label{
        span{
          color: $col4;
          &:after{
            opacity: 1;
            visibility: visible;
            border-color: $col4;
            color: lighten($col5,10%);
          }
        }
      }
    }
  }
}

.invoice-upload-input{
  input{
    @include hide_input();
    // &+label{}
  }
}

.cursor-pointer{
  cursor: pointer;
}

.vs--disabled {
  background-color: #00FF00;
  .vs__dropdown-menu,
  .vs__dropdown-toggle,
  .vs__selected-options input,
  .vs__actions button,
  .vs__actions svg {
    background: transparent;
  }
}

.form-list-project {
  .vs__search {
    margin: 0;
    padding: 0;
    color: white;
    &::placeholder {
      color: white;
    }
  }
  .vs__dropdown-toggle {
    background: none;
  }
  .vs__dropdown-menu {
    background-color: $dark;
    border: 1px solid #F79A00;
    color: white;
    li {
      color: white;
      background-color: $dark;      
    }
    .vs__dropdown-option--highlight {
      background-color: $col4 !important;
    }
  }
  .vs__clear{
    transform: translate(-10px, -2px);
  }
  .vs__clear,
  .vs__open-indicator {
    fill: white;
  }
  .vs__actions {
    padding: 1px 10px;
  }
  .vs__selected {
    color: white;
    padding: 0;
    margin: 0;
  }
  &.vs--searchable {
    .vs__dropdown-toggle {
      border: none;
    }
  }
}

.currency-addon {
  .input-group-text {
    background: #262F49;
    border: none;
    color: inherit;
  }
}
#dateValidate {
  color: #c82333;
  font-size: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.pagination-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item.disabled .page-link {
  background-color: transparent;
  color: #ddd;
  border-color: #ddd;
}

.page-link {
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  border-color: #fff;
}

.page-item.active .page-link {
  background-color: #fff;
  color: #080a29;
}
.pdf{
  display: block;
  width: 57px;
  height: 33px;
  background-image: url('/images/pdf.png');
  background-position: 0px -8px;
  background-repeat: no-repeat;
  background-size: 75%;
}
.preview-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}