.table {
  color: white;
  &:not(.rounded-table) {
    border-radius: 0;
  }
  thead th {
    background: $col5;
    border: none;
    // font-size: 12px;
  }

  tbody {
    td {
      background: rgba($col5, 0.5);
    }
  }

  th,
  td {
    border: none;
    vertical-align: middle;
  }

  .td-avatar-wrap {
    width: 35px;
    height: 35px;
    margin: 0 auto;
    border-radius: 50%;
    background: $col5;
    overflow: hidden;
  }

  .payment-mode-img {
    width: 70px;
  }

  tr {
    &.selected {
        background-color: lighten($col5, 0.1) !important;
    }
  }

  &.coder-table {
    $shadow-offset: 2px;
    box-shadow: $shadow-offset $shadow-offset 0 $col4;
  }
}
