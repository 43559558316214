.font {
    &-medium {
        font-weight: 500;
    }

    &-semibold {
        font-weight: 600;
    }

    &-bold {
        font-weight: 700;
    }

    &-accent {
        color: $col4;
    }

    &-alt-light{
        color: $alt_light;
    }

    &-alt-accent{
        color: $col3;
    }
    
    &-sec{
        font-family: $font2;
    }

    &-extra-small{
        font-size: 12px;
    }
    &-pseudo-underline{
        &:after{
            content:'';
            display: block;
            height: 0.1em;
            width: 100%;
            margin: 0.15em 0;
            border-radius: 2px;
            background: currentColor;
        }
    }

}