$font1: 'Open Sans', sans-serif;
$font2: 'Open Sans', sans-serif;

$dark : #262F49;
$dark_alt : #474F73;
$btn_dark : #202637;

$alt_light: #969BB5;


$col1: #252851;
$col1_o8: #252851cc;
$col2: #0B1821;
$col3: #E0B3A6;
$col4: #F79A00;
$col5: #2B3450;

// $theme-red:#CD4439
$theme-red: #EF4136;
$theme-purple:#534FD3;
$theme-bg-1:#15183E;
$theme-bg-2:#030526;


$accent:$col4;
$green: #28a745;

$theme : (
  'primary': $theme-red,
);

$rad_in : rgba(#13164A, 1);
$rad_out :rgba(#000110, 3);

.color-purple{
  color: $theme-purple;
}
