@mixin bg_gradient() {
	background: #13164a;
	background: -moz-radial-gradient(center, ellipse cover, #13164a 0%, #000110 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #13164a 0%, #000110 100%);
	background: radial-gradient(ellipse at center, #13164a 0%, #000110 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#13164a', endColorstr='#000110', GradientType=1);
}

@mixin bg-center() {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

@mixin hide_input() {
	opacity: 0;
	height: 0px;
	width: 0px;
	position: absolute;
	z-index: -9999;
}

@mixin placeholder() {
	&::-webkit-input-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
}
