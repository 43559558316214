html,
body {
    height: 100%;
    min-height: 100%;
}

html {
    overflow: hidden;
}

a {
    color: #F79A00;
}

a:hover {
    color: #F79A00;
}

body {
    $opacity: .7;
    overflow: auto;
    font-family: $font1;
    background-image:
        radial-gradient(rgba($rad_in, $opacity), rgba($rad_out, $opacity)),
        url('/images/world.svg'),
        radial-gradient(rgba($rad_in, 1), rgba($rad_out, 1));

    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    color: white;
}

.admin-wrap {
    overflow: hidden;
}

//admin menu
.admin-menu {
    width: 0;
    overflow: hidden;

    &.active {
        width: 100px;
    }

    height: 100%;
    background: $col5;
    flex-grow: 0;

    .nav {
        &.nav-auto {
            margin-top: auto;
        }

        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            list-style-type: none;
            padding: 0;
        }

        li {

            a {
                padding: 20px 0;
                border-right: 2px solid transparent;
                color: inherit;
                font-size: 13px;
                text-decoration: none;

                span {
                    margin-top: 5px;
                }

                .menu-icon {
                    max-height: 40px;
                }

                &:hover {
                    background: lighten($col5, 10%);
                }



                &.router-link-exact-active {
                    color: $col4;
                    border-right-color: currentColor;

                    svg {
                        stroke: $col4;
                    }
                }

            }

        }

        .social-icons-side {
            align-items: center;

            li {
                margin-top: 5px;
                list-style-type: none;
                a {
                    color: $accent;
                    opacity: .7;

                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

.payer-footer-bg {
    background: rgba($col5, 0.5);
}

.fixed-bottom-custom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    height: 223px;
}

.payer-margin {
    padding-bottom: 223px;
}
.active-color{
    color: #f8f9fa;
}
.inactive-color{
    color: #F79A00;
}
.highlight-darkred{
    background-color: darkred;
}