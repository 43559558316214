/**
        FORMS
**/

.form-group {
	font-family: $font1;
}

.user-form {
	margin-top: 30px;
	.profile-picture {
		img {
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
		}
	}
}

form {
	.form-section {
		margin: 10px 0;
	}
	.form-section-title {
		color: $accent;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 20px;
		margin: 20px 0;
	}
	.form-group {
		.form-control {
			background: $dark;
			border: none;
			color: inherit;
			&:focus {
				background: $dark;
				color: inherit;
				border-color: $dark_alt;
			}

			&.component__description,
			&.component__contract {
				height: auto;
			}
		}

		label {
			font-size: 11px;
			font-family: $font2;
		}

		textarea {
			min-height: 100px;
		}
	}

	.logos-payment {
		align-items: center;
	}

	.button-container {
		padding: 2em 0;
	}

	.help-link {
		color: $accent;
		display: block;
		text-align: center;
		text-decoration: underline;
	}
}

/**
      LOGIN/REGISTRATION PAGES/COMPONENTS
**/

.auth-wrap {
	height: 100%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.auth-content {
		display: flex;
		justify-content: center;
		padding: 2em;
		padding-top: 1em;
		position: relative;
		z-index: 5;
		width: 100%;
	}

	.auth-logo-wrap {
		text-align: center;
		img {
			display: inline-block;
			height: 125px;
		}
	}

	.auth-container {
		padding: 1em 2em;
		background: $col1_o8;
		min-width: 300px;
		max-width: 400px;
		width: 50%;
		box-shadow: 0 5px 25px rgba(black, .4);
		border-radius: 5px;

		form {
			input {
				background: $col2;
				border: 2px solid $col1;
				border-radius: 18px;
				&.is-valid {
					border-color: rgba(255,255,255, 0.3);
				}
				&.is-invalid {
					border-color: #dc3545;
				}
				&:focus {
					box-shadow: 0 0 0 0.1rem $col1;
				}
			}

			label {
				padding: 0em 1em;
			}

			input,
			label {
				color: inherit;
				font-size: 15px;
			}

			.button-container {
				padding: 2em 0;
			}

			.help-link {
				color: $alt_light;
				display: block;
				text-align: center;
				font-size: 13px;
				// text-decoration: underline;
			}
		}

	}
}


.component-form{
  .form-control{
    @include placeholder(){
      color: rgba(white,.45)
    }
  }
}

.form-pills{
	label{
		font-size: inherit;
	}
	.form-check-item{
		label span{
			font-size: 15px;
		}
		input{
			opacity: 0;
			height: 0;
			width: 0;
			position: absolute;
			z-index: -99999;
			&:checked{
				&+label span{
					background: $accent;
					color: $btn_dark;
				}
			}
		}
	}

}