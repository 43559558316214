.btn {
	white-space: nowrap;
	
	&.btn-google {
        background: transparent;
		box-shadow: none;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.btn-feather {
		background: transparent;
		border: none;
		padding: 0;
		box-shadow: none;
		border-radius: 0;
		color: white;
	}

	&.log-out{
		color: white;
		box-shadow: none;
	}
}

.custom-icon {
	&.ic-google {
		content: '';
		width: 50px;
		height: 20px;
		background-image: url('/svg/general/google-icon.svg');
		@include bg-center;
	}
}

.vdp-datepicker__calendar {
	background-color: $theme-bg-1 !important;

	header {
		span {
			&:hover {
				background-color: $theme-bg-2 !important;
			}

			&.prev {
				&:after {
					color: white !important;
				}
			}

			&.next {
				&:after {
					color: white !important;
				}
			}
		}
	}
}

.modal-content {
	background-color: #030526;
}

.modal-header {
	border-bottom: 1px solid rgba(255,255,255,.1);
	.close {
		color: #FFF;
	}
}
#modal-payment {

	 #fieldset-hours {
		 margin: 0;
	 }
	.trash-icon {
		cursor: pointer;
		&:hover {
			stroke: $theme-red;
		}
	}
	.add-payment-button {
		position: absolute;
		width: 40px;
		padding: 0;
		svg {
			margin: 0;
		}
	}
}

.auth-form {
	.alert {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid;
		padding: 0.75rem 0;
		border-radius: 0;
		&.alert-danger {
			color: $theme-red;
			border-bottom-color: $theme-red;
		}
		&.alert-info {
			color: $theme-purple;
			border-bottom-color: $theme-purple;
		}
	}
}

.success-text {
    color: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    svg {
        &.success-icon.feather {
            margin-right: 5px;
            color: $green;
            width: 30px;
            height: 30px;
        }
    }
}

.accent-text {
    color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    svg {
        &.accent-icon.feather {
            margin-right: 5px;
            color: $accent;
            width: 30px;
            height: 30px;
        }
    }
}
.custom-control-label{
    &:before {
        top: 0rem;
    }

    &:after {
        top: 0rem;
    }
}

.or {
    margin: 5px 0px;
    small {
        color: $alt_light;
    }
}

.btn{
    &.btn-social {
        img {
            margin-right: 10px;
        }

        &:hover {
            background: none !important;
            color: $accent !important;
        }
    }

    &.btn-connect {
        color: #FFF;
        border: 2px solid accent;
        background-color: $accent;
    }
}

.toast-body {
	text-align: center;
}

