table {
  td {
    svg {
      stroke-width: 1px;
    }
  }
}

h2.dash-header-title {
  font-size: 1.6rem;
}

.component-info-list {
  border-radius: 5px;
  opacity: .8;
  padding: 10px;
  background: #252851;
}

.container-fluid.dash {
  max-width: 1280px;
  margin: 0 auto;

  .notification {
    background: $col4;
    border-radius: 5px;

    svg {
      margin: 0 0.75em;
    }
  }

  .card {
    background: $col1;
    margin: 1em;
	border: none;
	$shadow-offset:2px;
    opacity: .6;

    &:first-child {
      margin-top: 0;
    }

    hr {
      border: none;
      border-top: 1px solid rgba($col4, 0.5);
    }

    svg {
      stroke-width: 1px;
    }
  }

  .user-info {
    .avatar {
      .img-wrap {
        border: 2px solid $col4;
        padding: 2px;

        &,
        img {
          border-radius: 50%;
        }

        img {
          $rad: 150px;
          height: $rad;
          width: $rad;
          object-fit: cover;
        }
      }
    }
  }

  .user-stats {
    .user-stat {
      .stat-label {
        font-family: $font2;
        font-size: 10px;
        font-weight: 700;
        color: $alt_light;
      }
    }
  }

  .user-data {
    .categories {
      a {
        color: $alt_light;
        border-bottom: 1px solid;
        font-family: $font2;
        font-weight: 500;
        width: 80px;
        height: 30px;

        &.active {
          color: $col4;
        }
      }
    }

    .payment-chart {
      width: 100%;
    }
  }

  .coder{
	  &-cards{
		  width: 370px;
	  }
  }

  .dash-table {
    table {
      padding: 0.5em;

      th,
      tr,
      td {
        height: 50px;
        // padding: 1em;
      }

      &.rounded-table {
        tr {
          overflow: hidden;
          border-radius: 5px;

          td {
            background: $col1;
          }
        }
        //radius
        $rad: 5px;

        tr:first-child td:first-child {
          border-top-left-radius: $rad;
        }

        tr:first-child td:last-child {
          border-top-right-radius: $rad;
        }

        tr:last-child td:first-child {
          border-bottom-left-radius: $rad;
        }

        tr:last-child td:last-child {
          border-bottom-right-radius: $rad;
        }
      }

      thead {
        th {
          color: $col3;
        }
      }

      tbody {
        font-size: 12px;
        font-family: $font2;
        box-shadow: 0 0 10px rgba(black, 0.1);
      }
    }

  }

  .dash-graph {
    margin: 30px auto;
    border-bottom: 1px solid white;

    .graph-bar {
      display: block;
      width: 8px;
      content: "";
      background: darken($col1, 4%);
      $rad: 2px;
      border-radius: $rad $rad 0 0;
      position: relative;

      &.active {
        background: $col4;
      }

      &.semi-active {
        background: rgba($col4, 0.4);
      }

      &:before {
        content: attr(data-figure);
        display: none;
        font-family: $font2;
        background: darken($col1, 10%);
        position: absolute;
        top: -40px;
        left: 3px;
        transform: translateX(-50%);
        padding: 2px 3px;
        border-radius: 3px;
      }

      &.active {
        &:before {
          display: block;
        }
      }

      &:after {
        content: attr(data-month);
        display: block;
        position: absolute;
        bottom: -30px;
        transform: translateX(-50%);
        left: 50%;
        color: $alt_light;
        font-size: 12px;
        font-weight: 600;
        font-family: $font2;
      }

      &:nth-of-type(even) {
        &:after {
          display: none;
        }
      }
    }
  }
}

.page-title {
  > h1 {
    flex: 1;
    margin-left: -50px;
    margin-bottom: 0;
  }
}

@mixin metric-theme($color: $accent) {
  border-bottom: 3px solid $color;

  svg.icon {
    stroke: $color;
  }
}

.admin-dash {
  margin: 0 auto;
  overflow: auto;

  .metrics-board {
    .metric {
      width: 100%;
      padding: 0 0.4em;
      margin: 0 10px;
      padding-bottom: 10px;

      svg {
        $r: 50px;
        stroke-width: 2px;
        height: $r;
        width: auto;

        .icon {
          // width: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .metric-data {
        padding: 0 10px;

        h1 {
          font-size: 27px;
        }

        span {
          font-size: 0.8em;
        }
      }

      &.metric-1,
      &.metric-4 {
        @include metric-theme($theme-red);
      }

      &.metric-2 {
        @include metric-theme($theme-purple);
      }

      &.metric-3 {
        @include metric-theme();
      }

      &.metric-5 {
        @include metric-theme($col3);
      }
    }
  }

  .admin-table {
    .user-table {
      .table-header {
        background: $theme-bg-1;
        padding: 10px 0;
        opacity: .7;

        .table-control {
          width: 70px;
        }

        .user-notif {
          background: $accent;
          color: white;
          border-radius: 50%;
          $rad: 25px;
          height: $rad;
          line-height: $rad;
          width: $rad;
          display: block;
          text-align: center;
        }

        .user-details {
          svg {
            height: 15px;
          }
        }

        .img-wrap {
          $r: 70px;

          img {
            height: $r;
            width: $r;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            background-color: #2b3450;
          }
        }
      }

      .user-content-row {
        background: $theme-bg-2;
        margin: 1px auto;
        line-height: 50px;
        font-size: 12px;
      }

      &:first-child {
        .table-header {
          $r: 5px;
          border-radius: $r $r 0 0;
        }
      }
    }
  }
}
.dollar {
  display: block;
  width: 120px;
  height: 36px;
  background-image: url('/images/dollar.png');
  background-position: 9px -8px;
  background-repeat: no-repeat;
  background-size: 43%;
}
.euro{
  display: block;
  width: 120px;
  height: 36px;
  background-image: url('/images/euro.png');
  background-position: 9px -8px;
  background-repeat: no-repeat;
  background-size: 43%;
}