.brand-logo{
    height: 100px;
}

.burger-icon{
    transform: rotate(90deg);
}

.coder-wrap{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}