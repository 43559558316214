.date-range-filter {

    .mx-calendar,.mx-datepicker{
        font: inherit;
    }

    .mx-datepicker-range{
        width: auto;
    }

    .mx-input {
        background-color: transparent;
        border: none;
        margin-left: 3px;
        padding: 0.375rem 0.75rem;
        @include placeholder() {
            font-size: 15px;
            color: rgba(white, 0.45);
        }
    }

    .mx-datepicker-popup {
        background-color: $theme-bg-1;
        color: white;
        border: 1px solid #ccc;
    }

    .mx-shortcuts-wrapper{
        display: flex;
    }

    .mx-range-wrapper {
        display: flex;
        width: auto;
    }

    .mx-panel {
        color: white;
    }

    .mx-panel-date {
        border-collapse: separate;
        td {
            padding: 0 5px;
        }
        th {
            font-weight: normal;
        }
    }

    .mx-calendar-header{
        color: white;

        .mx-current-month,.mx-current-year{
            font-size: 16px;
        }
    }

    .mx-calendar-content {
        .cell {
            border: 1px solid transparent;
            &:hover {
                border-color: #4bd;
                background: unset;
            }

            &.last-month,&.next-month{
                opacity: .5;
            }

            &.disabled {
                background: darken($theme-bg-1, 50%);
                opacity: 0.5;
                color: rgba(white,.4);
            }

            &.inrange:not(.disabled){
                background-color: #1284e7;
                color: white;
            }
        }
    }
}

.date-filter{
    .vdp-datepicker__calendar {
        padding: 6px 12px;
        width: 260px;
        .cell {
            font-size: 12px;
            height: 32px;
            line-height: 32px;
        }
    }
}


.date-range-filter .mx-input,
.date-filter .vdp-datepicker .form-control{
    min-width: 250px;
}